import React, { useEffect, useRef, useState } from 'react'
import './Testing.scss'

export default function Testing() {

    const [boxes, setBoxes] = useState([])

    console.log({ boxes })

    return (
        <div id='Container'>
            <div className="buttons">
                <button onClick={() => setBoxes(s => ([...s, { left: 0, bottom: 0 }]))}>+</button>
            </div>
            <div className="boxes">
                {
                    boxes?.map((box, i) => {
                        return <Box details={box} key={i} />
                    })
                }

            </div>
        </div>
    )
}

export const Box = ({ details }) => {

    const [isDragging, setIsDragging] = useState(false)
    console.log({ isDragging })

    document.addEventListener('mouseup', () => setIsDragging(false))
    function onMouseMove(e) {
        // console.log(e.target.style.left, e.movementX)
        // console.log(parseInt(e.target.style.left), e.movementX)
        // console.log(parseInt(e.target.style.bottom), e.movementY)
        if (isDragging) {
            let x = parseInt(e.target.style.left) + e.movementX + 'px'
            let y = parseInt(e.target.style.bottom) - e.movementY + 'px'
            console.log(x, y)
            // let leftEnd = e.target.parentElement.offsetWidth - e.target.offsetWidth
            // let topEnd = e.target.parentElement.offsetHeight - e.target.offsetHeight
            // e.target.style.left = parseInt(x) < 0 ? 0 : parseInt(x) > leftEnd ? leftEnd : x
            // e.target.style.bottom = parseInt(y) < 0 ? 0 : parseInt(y) > topEnd ? topEnd : y
            e.target.style.left = x
            e.target.style.bottom = y

            // setBoxes((s) =>
            //     s.map((box, i) => {
            //         if (box.sl_no === parseInt(e.target.id.replace('sign-box', ''))) return ({ ...box, x, y })
            //         return box
            //     })
            // )
        }
    }


    return (
        <div id='Box' style={{ left: details.left, bottom: details.bottom }}
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}
            onMouseLeave={() => setIsDragging(false)}
            onMouseMove={(e) => onMouseMove(e)}
        >
            {/* <p>box</p> */}
        </div>
    )
}