import React, { Fragment, useState, useEffect } from 'react'
import './NavigationBar.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import { logout } from '../../redux/actionCreator'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../redux/commonReducer'

export default function NavigationBar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const [selected, setSelected] = useState({ item: 0, subItem: null })

    function logoutHandler() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        navigate('/login')
    }


    const menuItems = [
        { title: 'Dashboard', icon: 'dashboard', access: 'secretery', navPath: '/' },
        {
            title: 'Manage Requests', icon: 'manage_requests', navPath: '',
            subItems: [
                { title: 'Create Request', icon: 'create_request', access: 'secretery', navPath: 'manage_requests/create_request' },
                { title: 'Pending Requests', icon: 'pending_requests', navPath: 'manage_requests/pending_requests' },
                { title: 'Closed Requests', icon: 'closed_requests', navPath: 'manage_requests/closed_requests' },
                { title: 'InProgress Requests', icon: 'closed_requests', navPath: 'manage_requests/inprogress_requests' },
            ]
        },
        {
            title: 'Manage User Roles', icon: 'manage_user_roles', access: 'super_admin', navPath: '', subItems: [
                { title: 'Add Signature', icon: 'add-signature', navPath: 'manage_user_roles/add_signature' },
                { title: 'Assign Role', icon: 'assign-role', navPath: 'manage_user_roles/assign_role' },
            ]
        },
        // { title: 'Reports', icon: 'reports', navPath: '' },
    ]



    // console.log('useeeeeeeeeeeeer', user)

    return (
        <div id='NavigationBar'>
            <header>
                <img src="/assets/images/TSD logo.png" alt="" />
                <hr />
            </header>
            <ul>
                {
                    menuItems.map((item, i) => {
                        return (
                            <Fragment key={i}>
                                {
                                    user?.user_type !== 'super_admin' && (item.access === 'super_admin') ? null
                                        : !user?.is_secretery && item.access === 'secretery' ? null
                                            : <NavLink to={item.navPath} onClick={() => setSelected({ item: i, subItem: 0 })}>
                                                <li className={'item' + (selected.item === i ? ' active' : '')}>
                                                    <img src={`/assets/icons/${item.icon}.svg`} alt="" />
                                                    <p>{item.title}</p>
                                                </li>
                                            </NavLink>
                                }
                                {
                                    item.subItems && selected.item === i &&
                                    <ul>
                                        {
                                            item.subItems.map((subItem, si) => {
                                                return (
                                                    !user?.is_secretery && subItem.access === 'secretery' ? null
                                                        : <NavLink NavLink key={si} to={subItem.navPath} onClick={() => setSelected({ item: i, subItem: si })}>
                                                            <li className={'subitem' + (selected.subItem === si ? ' active' : '')}>
                                                                <img src={`/assets/icons/${subItem.icon}.svg`} alt="" />
                                                                <p>{subItem.title}</p>
                                                            </li>
                                                        </NavLink>
                                                    // : null
                                                )
                                            })
                                        }
                                    </ul>
                                }
                            </Fragment>
                        )
                    })
                }
                <NavLink onClick={() => logoutHandler()}>
                    <li>
                        <img src="/assets/icons/logout.svg" alt="" />
                        <p>Logout</p>
                    </li>
                </NavLink>
            </ul>
        </div >
    )
}