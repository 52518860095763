import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

function ProtectedRoute() {
    const token = localStorage.getItem('token')
    const { pathname } = useLocation()

    let auth = { 'token': token }

    if (pathname === '/login' && auth.token) {
        return <Navigate to={'/'} />
    } else if (pathname !== '/login' && !auth.token) {
        return <Navigate to={'/login'} />
    } else {
        return <Outlet />
    }
}

export default ProtectedRoute