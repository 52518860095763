import React, { useEffect, useRef, useState } from 'react'
import './PDFviewer.scss'
import { Document, Page, pdfjs } from 'react-pdf';
import { capitalize } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setPdfHeaderSelectedBtn } from '../../redux/actionCreator';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export let onButtonClick;
export let pageChangeHandler;
export default function PDFviewer({ url, setPagesCount, onChange }) {

    // let A4size = { width: 2480 / 2.2568, height: 3508 / 2.2568 }
    let A4size = { width: 595 * 1.85, height: 842 * 1.85 }

    const dispatch = useDispatch()
    const selectedBtns = useSelector(s => s.commonReducer.pdfHeaderBtns)
    const [boxes, setBoxes] = useState([])
    const [isDragging, setIsDragging] = useState(false)
    const [pages, setPages] = useState({ current: 1, totalPages: 1 })
    const [templateBox, setTemplateBox] = useState()

    const BoxModel = useRef()


    onButtonClick = (sl_no, name, signatory_id) => {
        let result = boxes.some((box) => box.signatory_id === signatory_id)
        if (result) return

        let box = templateBox
        let newBox = box.cloneNode(true)
        newBox.id += sl_no
        newBox.style.display = 'flex'
        newBox.style.left = '0px'
        newBox.style.bottom = '0px'
        newBox.querySelector('span').addEventListener('click', (e) => boxDeleteHandler(e));
        newBox.addEventListener('mousedown', (e) => e.target.classList.add('dragging'))
        newBox.addEventListener('mouseup', (e) => e.target.classList.remove('dragging'))
        newBox.childNodes[0].textContent = capitalize(name)

        dispatch(setPdfHeaderSelectedBtn({ ...selectedBtns, [sl_no]: true }))
        setBoxes((s) => ([...s, { element: newBox, x: 0, y: 0, sl_no: sl_no, signatory_id: signatory_id, page: pages.current }]))
        const scrollHeight = document.documentElement.scrollHeight;
        window.scrollTo(0, scrollHeight)
    }


    pageChangeHandler = (value) => {
        // console.log(value)
        if (value === -1 && pages.current === 1) return
        if (value === 1 && pages.current === pages.totalPages) return
        setPages(s => ({ ...s, current: s.current + value }))
    }

    useEffect(() => {
        setPagesCount(pages)
    }, [pages])


    function onMouseMove(e) {
        if (isDragging && e.target.id.includes('sign-box')) {
            let x = parseInt(e.target.style.left) + e.movementX + 'px'
            let y = parseInt(e.target.style.bottom) - e.movementY + 'px'
            let leftEnd = e.target.parentElement.offsetWidth - e.target.offsetWidth
            let topEnd = e.target.parentElement.offsetHeight - e.target.offsetHeight
            e.target.style.left = parseInt(x) < 0 ? 0 : parseInt(x) > leftEnd ? leftEnd : x
            e.target.style.bottom = parseInt(y) < 0 ? 0 : parseInt(y) > topEnd ? topEnd : y

            setBoxes((s) =>
                s.map((box, i) => {
                    if (box.sl_no === parseInt(e.target.id.replace('sign-box', ''))) return ({ ...box, x, y })
                    return box
                })
            )
        }
    }

    function onPdfLoadingSuccess(pdf) {
        setPages(s => ({ ...s, totalPages: pdf.numPages }))
        // console.log(pdf)
    }


    function boxDeleteHandler(e) {
        console.log('asdfasdf', e)
        e.target.parentElement.remove()
        setBoxes((s) => s.filter((obj, i) => {
            dispatch(setPdfHeaderSelectedBtn({ ...selectedBtns, [obj?.sl_no]: false }))
            return obj.element !== e.target.parentElement
        }))
    }

    document.addEventListener('mouseup', () => {
        setIsDragging(false)
        let outerBox = document.getElementById('boxes')
        if (outerBox) {
            const childElements = outerBox.children;
            for (let i = 0; i < childElements.length; i++) {
                childElements[i].classList.remove("dragging");
            }
        }
    })

    // console.log(templateBox)
    useEffect(() => {
        let outerBox = document.getElementById('boxes')
        setTemplateBox(BoxModel.current)
        const timer = setTimeout(() => {
            outerBox.replaceChildren()
            boxes.forEach((box) => {
                if (outerBox) {
                    if (box.page === pages.current) {
                        outerBox.appendChild(box.element)
                    }
                }
            })
            onChange(boxes)
        }, 500);
        return () => clearTimeout(timer);
    }, [boxes, pages])



    // console.log(pages,boxes)
    // console.log('boxes', boxes)
    // console.log('draggin', isDragging)
    // console.log(boxes.filter(box => box.page === pages.current))

    return (
        <div id='PDFviewer'>
            <div className='wrapper' style={{ width: A4size.width, height: A4size.height }}>
                <div className="pdf-preview-div">
                    <Document file={url} onLoadSuccess={onPdfLoadingSuccess}>
                        <Page
                            pageNumber={pages.current}
                            renderTextLayer={false}
                        />
                    </Document>
                </div>
                <div id='boxes'
                    onMouseDown={() => setIsDragging(true)}
                    onMouseUp={() => setIsDragging(false)}
                    onMouseMove={onMouseMove}
                >
                    <div ref={BoxModel} id='sign-box' className='sign-box'>
                        <p>Signatory</p>
                        <span>Delete<img src="/assets/icons/delete1 green.svg" alt="" /></span>
                    </div>
                </div>
            </div >
        </div >
    )
}

export const SignatureButton = ({ sl_no, name, signatory_id }) => {
    const selectedBtns = useSelector(s => s.commonReducer.pdfHeaderBtns)
    return <button className={selectedBtns?.[sl_no] ? ' active' : ''} onClick={() => onButtonClick(sl_no, name, signatory_id)}> {capitalize(name)}</button>
}