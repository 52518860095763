import React, { useEffect, useState } from 'react'
import './Table.scss'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export default function Table({ page, titles, data, setModalShow, onEdit, onCheckboxChange }) {


    let flag =
        page === 'dashboard' ? 1
            : page === 'pending requests' ? 2
                : page === 'add signature' ? 3
                    : 0

    const navigate = useNavigate()

    // console.log({ data })

    return (
        <div id='Table'>
            <section className='table'>
                <table>
                    <thead>
                        <tr>
                            {
                                titles.map((title, i) => {
                                    return (
                                        <th key={i} style={{ width: title.width }}>{title.title}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((datom, i) => {
                                return (
                                    <tr key={i}>
                                        {
                                            titles.map((title, si) => {
                                                return (
                                                    <td key={si} dir={title.key === 'createdAt' ? 'ltr' : null}>
                                                        {
                                                            title.key === 'actions' && flag === 1 ?
                                                                <>
                                                                    <img className='eye' src="/assets/icons/eye.svg" alt="" onClick={() => navigate(`request_details/${datom._id}`)} />
                                                                    {/* <img className='delete' src="/assets/icons/delete1.svg" alt="" /> */}
                                                                    {/* <img className='eye' src="/assets/icons/bell.svg" alt="" /> */}
                                                                </>
                                                                : title.key === 'action' && flag === 2 ?
                                                                    <img className='eye' src="/assets/icons/eye.svg" alt="" onClick={() => navigate(`request_details/${datom._id}`)} />
                                                                    : title.key === 'actions' && flag === 3 ?
                                                                        <>
                                                                            <img className='edit' src="/assets/icons/edit.svg" alt="" onClick={() => (onEdit({ userId: datom._id, signPng: datom.signature_path, username: datom.username }), setModalShow(true))} />
                                                                            {/* <img className='delete' src="/assets/icons/delete.svg" alt="" /> */}
                                                                        </>
                                                                        : title.key === 'created_by'
                                                                            ? datom[title.key][0].name
                                                                            : title.key === 'secretary_permission'
                                                                                ? <input type="checkbox" checked={datom.is_secretery} onChange={(e) => onCheckboxChange(e.target.checked, datom._id, datom.name)} />
                                                                                : title.key === 'createdAt'
                                                                                    ? moment(datom[title.key]).format('DD MMM yyyy')
                                                                                    : datom[title.key]
                                                        }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </section>
        </div>
    )
}
