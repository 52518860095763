import React, { useEffect, useState } from 'react'
import Table from '../components/Table/Table'
import PaginationRow from '../components/PaginationRow/PaginationRow'
import { getRequest } from '../redux/actionCreator'
import moment from 'moment'

export default function Dashboard() {

    let Titles = [
        { title: 'Request ID', key: 'request_id', width: '120px' },
        { title: 'Title', key: 'title', },
        { title: 'Current Status', key: 'status', width: '180px' },
        { title: 'Created Date', key: 'createdAt', width: '150px' },
        { title: 'Action', key: 'actions', width: '130px' },
    ]

    const [data, setData] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [limit, setLimit] = useState(10)

    function setUpStatus(details) {
        let string;
        if (details.currentStatus === 'pending') { string = 'Sent to : ' }
        if (details.currentStatus === 'approved') { string = 'Approved by : ' }
        if (details.currentStatus === 'rejected') { string = 'Rejected by : ' }
        string += details.currentId?.name + '\n' + moment(details.updatedAt).format('DD MMM YYYY')
        return <pre dir='ltr' className={details.currentStatus}>{string}</pre>
    }
    // window.location.reload()

    useEffect(() => {
        // console.log('useeffect')
        getRequest((res) => {
            setData([])
            // console.log(res)
            res.request_details?.filter(item => item.title.includes(searchKey)).slice(0, limit).forEach(item => {
                setData(s => ([...s, {
                    request_id: item.reqId,
                    title: item.title,
                    status: setUpStatus(item),
                    createdAt: item.createdAt,
                    _id: item._id
                }]))
            })
        })
    }, [searchKey, limit])


    function searchHandler(value) {
        setSearchKey(value)
    }

    // console.log({ data })

    return (
        <div id='Dashboard'>
            <h1 className='page-title'>Dashboard</h1>
            <section>
                <PaginationRow onSearch={(value) => searchHandler(value)} onCount={(count) => setLimit(count)} />
            </section>
            <section>
                <Table page={'dashboard'} titles={Titles} data={data} />
            </section>
        </div>
    )
}
