import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import './style.scss'
import { ToastContainer } from "react-toastify";

import ProtectedRoute from "./ProtectedRoute";
import Layout from "./components/Layout/Layout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CreateRequest from "./pages/ManageRequests/CreateRequest";
import ManageUserRoles from "./pages/ManageUserRoles/AddSignature";
import Reports from "./pages/Reports";
import Layout_Main from "./components/Layout_Main/Layout_Main";
import Layout_pdf from "./components/Layout_pdf/Layout_pdf";
import PendingRequests from "./pages/ManageRequests/PendingRequests";
import RequestDetailsPage from "./pages/ManageRequests/RequestDetailsPage";
import AssignSignatory from "./components/AssignSignatory/AssignSignatory";

import { useSelector } from "react-redux";
import ClosedRequests from "./pages/ManageRequests/ClosedRequests";
import AssignRole from "./pages/ManageUserRoles/AssignRole";
import Testing from "./pages/testing/Testing";
import InProgress from "./pages/ManageRequests/InProgress";

export default function App() {
  // const wholeStore = useSelector((s) => console.log(s))
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/login" element={<Login />} />
            <Route element={<Layout />}>
              <Route element={<Layout_Main />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="request_details/:id" element={<RequestDetailsPage />} />
                <Route path="manage_requests">
                  <Route path="create_request" element={<CreateRequest />} />
                  <Route path="pending_requests" >
                    <Route index element={<PendingRequests />} />
                    <Route path="request_details/:id" element={<RequestDetailsPage page={'pending'} />} />
                  </Route>
                  <Route path="closed_requests" >
                    <Route index element={<ClosedRequests />} />
                    <Route path="request_details/:id" element={<RequestDetailsPage page={'closed'} />} />
                  </Route>
                  <Route path="inprogress_requests">
                    <Route index element={<InProgress />} />
                    <Route path="request_details/:id" element={<RequestDetailsPage page={'in-Progress'} />} />
                  </Route>
                  <Route path="assign_signatory" element={<AssignSignatory />} />
                </Route>
                <Route path="manage_user_roles" >
                  <Route path="add_signature" element={<ManageUserRoles />} />
                  <Route path="assign_role" element={<AssignRole />} />
                </Route>
                <Route path="reports" element={<Reports />} />
              </Route>
              <Route path="/manage_requests/create_request/document/:id" element={<Layout_pdf />} />
              <Route path="/manage_requests/assign_signatory/document/:id" element={<Layout_pdf name={'assign_signatory'} />} />
            </Route>
          </Route>
          <Route path="/testing" element={<Testing />} />
        </Routes>
      </Router>
    </div>
  );
}