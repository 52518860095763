import React, { useEffect, useState } from 'react'
import Table from '../../components/Table/Table'
import PaginationRow from '../../components/PaginationRow/PaginationRow'
import { useDispatch } from 'react-redux'
import { getPendingRequest } from '../../redux/actionCreator'

export default function PendingRequests() {

    let Titles = [
        { title: 'Request ID', key: 'reqId', width: '120px' },
        { title: 'Title', key: 'title', },
        { title: 'Requester', key: 'created_by', width: '180px' },
        { title: 'Created Date', key: 'createdAt', width: '150px' },
        { title: 'Action', key: 'action', width: '130px' },
    ]

    const [data, setData] = useState()
    const [searchKey, setSearchKey] = useState('')

    useEffect(() => {
        let userId = JSON.parse(localStorage.getItem('user'))._id
        getPendingRequest({ id: userId }, (res) => {
            setData(res.filter(item => item.title.includes(searchKey)))
        })
    }, [searchKey])
    function searchHandler(value) {
        setSearchKey(value)
    }

    return (
        <div id='PendingRequests'>
            <h1 className='page-title'>PENDING REQUESTS</h1>
            <section>
                <PaginationRow onSearch={(value) => searchHandler(value)} />
            </section>
            <section>
                <Table page={'pending requests'} titles={Titles} data={data} />
            </section>
        </div>
    )
}
