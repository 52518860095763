import React, { useContext, useEffect, useState } from 'react'
import './FormAddPerson.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSubUnits, getAllUnits, getAllUser, getUser, getUserList } from '../../redux/actionCreator'
import { RequestInputContext } from '../../pages/ManageRequests/CreateRequest'
import { AssignSignatoryContext } from '../AssignSignatory/AssignSignatory'
import Select from 'react-select'

export default function FormAddPerson({ name, serialNo = 1, inputs, setInputs, inputErrors }) {

    const CreateRequestContextValues = useContext(RequestInputContext)
    if (CreateRequestContextValues) { ({ inputs, setInputs, inputErrors } = CreateRequestContextValues) }
    const AssignSignatoryContextValues = useContext(AssignSignatoryContext)
    if (AssignSignatoryContextValues) { ({ inputs, setInputs, inputErrors } = AssignSignatoryContextValues) }

    const [dropDowns, setDropDowns] = useState({})

    useEffect(() => {
        getAllUnits({}, (res) => setDropDowns(s => ({ ...s, units: res })))
        getUserList({}, (res) => { setDropDowns(s => ({ ...s, users: res })) })
        let unitEnteredValue = inputs[name].find((o, i) => o.sl_no === serialNo)?.unit
        let subUnitEnteredValue = inputs[name].find((o, i) => o.sl_no === serialNo)?.subunit
        if (unitEnteredValue) subunitsPopulating(unitEnteredValue)
        if (subUnitEnteredValue) usersPopulating(subUnitEnteredValue)
    }, [])

    async function inputHandler(key, value) {
        setInputs((s) => (
            {
                ...s,
                [name]: s[name].some(obj => obj.sl_no === serialNo)
                    ? s[name].map(obj => obj.sl_no === serialNo ? ({ ...obj, [key]: value }) : obj)
                    : [...s[name], { sl_no: serialNo, [key]: value }]
            }
        ))
        if (key === 'unit') subunitsPopulating(value)
        if (key === 'subunit') usersPopulating(value)
    }

    function subunitsPopulating(value) {

        getAllSubUnits(value, (res) => { setDropDowns(s => ({ ...s, subUnits: res.subunit_details })) })
    }
    function usersPopulating(value) {
        // console.log('userpopulating', value, name, inputs, serialNo)
        let formdata = new FormData()
        name === 'Signatory' && formdata.append('unit', inputs.Signatory.filter(o => o.sl_no === serialNo)[0].unit)
        name === 'Secretary' && formdata.append('unit', inputs.Secretary.filter(o => o.sl_no === serialNo)[0].unit)
        formdata.append('subunit', value)
        name === 'Signatory' && getUser(formdata, (res) => { setDropDowns(s => ({ ...s, users: res })) })
        name === 'Secretary' && getAllUser(formdata, (res) => { setDropDowns(s => ({ ...s, users: res })) })

    }


    // console.log({ dropDowns })
    // console.log({ inputs })
    // console.log(inputs[name].find((o, i) => o.sl_no === serialNo)?.comment_allowed)

    return (
        <div id='FormAddPerson'>
            <h3 className='title'>{name + ' ' + serialNo}</h3>
            <div className="inputs">
                <span>
                    <label htmlFor="">Unit</label>
                    {/* <select name="" id="" defaultValue={''} value={inputs[name].find((o, i) => o.sl_no === serialNo)?.unit}
                        onChange={(e) => inputHandler('unit', e.target.value)}>
                        <option value="" hidden></option>
                        {
                            dropDowns.units && dropDowns.units.map((unit, i) => {
                                return (
                                    <option key={i} value={unit._id}>{unit.unit_name}</option>
                                )
                            })
                        }
                    </select> */}
                    <Select
                        name="unit"
                        options={dropDowns.units}
                        // isDisabled={fullUserList?.length == 0}
                        hideSelectedOptions={false}
                        // value={'643b62cf46883ca3a2b910a8'}
                        getOptionLabel={(option) => option.unit_name}
                        getOptionValue={(option) => option._id}
                        className="basic-select"
                        classNamePrefix="select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#31D68F",
                                primary: "#0D7B58",
                            },
                        })}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 48,
                                // minHeight: 35
                            })
                        }}
                        onChange={(e) => inputHandler('unit', e._id)}
                    />
                    <img className='dropdown-arrow' src="/assets/icons/arrow-down.svg" alt="" />
                    {/* <p className='error-message'>error message</p> */}
                </span>
                <span>
                    <label htmlFor="">Subunit</label>
                    {/* <select name="" id="" defaultValue={''} value={inputs[name].find((o, i) => o.sl_no === serialNo)?.subunit}
                        onChange={(e) => inputHandler('subunit', e.target.value)}>
                        <option value="" hidden></option>
                        {
                            dropDowns.subUnits && dropDowns.subUnits.map((subUnit, i) => {
                                return (
                                    <option key={i} value={subUnit._id}>{subUnit.subunit_name}</option>
                                )
                            })
                        }
                    </select> */}
                    <Select
                        name="unit"
                        options={dropDowns.subUnits}
                        // isDisabled={fullUserList?.length == 0}
                        hideSelectedOptions={false}
                        // value={user._id}
                        getOptionLabel={(option) => option.subunit_name}
                        getOptionValue={(option) => option._id}
                        className="basic-select"
                        classNamePrefix="select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#31D68F",
                                primary: "#0D7B58",
                            },
                        })}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 48,
                                // minHeight: 35
                            })
                        }}
                        onChange={(e) => inputHandler('subunit', e._id)}
                    />
                    <img className='dropdown-arrow' src="/assets/icons/arrow-down.svg" alt="" />
                    {/* <p className='error-message'>error message</p> */}
                </span>
                <span>
                    <label htmlFor="">Name</label>
                    {/* <select name="" id="" defaultValue={''} 
                    value={inputs[name].find((o, i) => o.sl_no === serialNo)?.name}
                        onChange={(e) => inputHandler('name', e.target.value)}>
                        <option value="" hidden></option>
                        {
                            dropDowns.users && dropDowns.users.map((user, i) => {
                                return (
                                    <option key={i} value={user._id}>{user.username}</option>
                                )
                            })
                        }
                    </select> */}
                    <Select
                        name="unit"
                        options={dropDowns.users}
                        // isDisabled={fullUserList?.length == 0}
                        hideSelectedOptions={false}
                        // value={user._id}
                        getOptionLabel={(option) => option.username}
                        getOptionValue={(option) => option._id}
                        className="basic-select"
                        classNamePrefix="select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#31D68F",
                                primary: "#0D7B58",
                            },
                        })}
                        styles={{
                            control: base => ({
                                ...base,
                                height: 48,
                                // minHeight: 35
                            })
                        }}
                        onChange={(e) => inputHandler('name', e._id)}
                    />
                    <img className='dropdown-arrow' src="/assets/icons/arrow-down.svg" alt="" />
                    {/* <p className='error-message'>error message</p> */}
                </span>
            </div>
            {name === 'Signatory' &&
                <span className='checkbox'>
                    <input type="checkbox" name="" id="" checked={inputs[name].find((o, i) => o.sl_no === serialNo)?.comment_allowed}
                        onChange={(e) => inputHandler('comment_allowed', e.target.checked)} />
                    <label htmlFor="">Action without comment</label>
                </span>
            }
        </div >
    )
}