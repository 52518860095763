import React, { useEffect, useRef, useState } from 'react'
import './Layout_pdf.scss'
import PDFviewer from '../PDFviewer/PDFviewer'
import Header_Pdf_Layout from '../Header_Pdf_Layout/Header_Pdf_Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { getRequestDetail, setPdfHeaderDetails, submitPDF } from '../../redux/actionCreator'
import { API_BASE_URL } from '../../constants/configuration'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

export default function Layout_pdf({ name }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pages, setPages] = useState()
    const [boxes, setBoxes] = useState()
    const [details, setRequestDetails] = useState()

    const pdfDetails = useSelector((s) => s.commonReducer.pdfHeaderDetails)

    const request_id = useParams()

    useEffect(() => {
        getRequestDetail(request_id.id, (res) => {
            setRequestDetails(res)
        })
    }, [])

    useEffect(() => {
        dispatch(setPdfHeaderDetails({
            pages: pages,
            signatories: details?.request_details?.signatory
        }))
    }, [pages, details])

    function submitHandler() {
        console.log(boxes.length, pdfDetails.signatories.length)
        if (boxes.length !== pdfDetails.signatories.filter(item => item.status === 'pending').length) {
            toast.error('Signature Boxes are not specified for all signatories', { position: "bottom-center", autoClose: 3000 })
            return
        }
        let formdata = new FormData()
        formdata.append('request_id', request_id.id)
        boxes.forEach((box, i) => {
            formdata.append(`xaxis[${i}]`, box.x)
            formdata.append(`yaxis[${i}]`, box.y)
            formdata.append(`signatory[${i}]`, box.signatory_id._id)
            formdata.append(`page[${i}]`, box.page)
        })
        submitPDF(formdata, (res) => {
            toast.success('Signatories assigned successfully', { position: "bottom-center", autoClose: 3000 })
            if (res.status) navigate('/')
        })
    }

    // console.log('pdf details', details)
    // console.log('boxes', boxes)

    return (
        <div id='Layout_pdf'>
            {/* <header className="document-header">
                <Header_Pdf_Layout pages={pages} signatories={details?.request_details?.signatory} />
            </header> */}
            <div className="document-platform">
                {/* <header>
                    <img className='logo-1' src="/assets/images/TSD logo.png" alt="" />
                    <img className='logo-2' src="/assets/icons/dubai-police green.svg" alt="" />
                </header> */}

                <PDFviewer url={`${API_BASE_URL}${details?.request_path}/${name === 'assign_signatory' ? 'output-' : ''}${details?.request_details?.pdf}`}
                    setPagesCount={setPages} onChange={(e) => setBoxes(e)} />
                <span className='buttons'>
                    {/* <button className='back' onClick={navigate('/manage_requests/create_request')}>Back</button> */}
                    <button className='submit' onClick={submitHandler}>Submit</button>
                </span>



            </div>
        </div>
    )
}
