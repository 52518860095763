import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { loginValidation } from '../utils/validation'
import { login } from '../redux/actionCreator'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [inputs, setInputs] = useState({})
    const [inputErrors, setInputErrors] = useState({})

    async function loginHandler() {
        const isValidated = await loginValidation({
            username: inputs.username,
            password: inputs.password
        }, setInputErrors)

        if (isValidated) {
            const data = {
                username: inputs.username,
                password: inputs.password
            }
            dispatch(login(data, res => {
                if (res.status) navigate('/')
                window.location.reload()
            }))
        }
    }


    return (
        <div id="Login">
            <div className="login-box">
                <img className='dubai-police-logo' src="assets/icons/dubai-police.svg" alt="" />
                <img className='tsd-logo' src="assets/icons/tsd-logo.svg" alt="" />
                <p className='header'>Sign into your Dashboard</p>
                <span>
                    <input type="text" placeholder='User Name'
                        className={(inputErrors?.username ? 'error' : '')}
                        onChange={(e) => setInputs(s => (setInputErrors(), { ...s, username: e.target.value }))}
                    />
                    {inputErrors?.username && <p className='error-message'>{inputErrors.username}</p>}
                </span>
                <span>
                    <input type="text" placeholder='Password'
                        className={(inputErrors?.password ? 'error' : '')}
                        onChange={(e) => setInputs(s => (setInputErrors(), { ...s, password: e.target.value }))}
                    />
                    {inputErrors?.password && <p className='error-message'>{inputErrors.password}</p>}
                </span>
                <button onClick={loginHandler}>Sign In</button>
            </div>
        </div>
    )
}
