import React from 'react'
import './Header_Pdf_Layout.scss'
import { SignatureButton, pageChangeHandler } from '../PDFviewer/PDFviewer'

export default function Header_Pdf_Layout({ pages, signatories }) {

    return (
        <div id='Header_Pdf_Layout'>
            <span className='signatory-selection'>
                <p>Select Signatory</p>
                <div>
                    {
                        signatories && signatories.filter(item => item.status === 'pending').map((item, i) => {
                            return (
                                <SignatureButton key={i} name={item.signatory.name} sl_no={i + 1} signatory_id={item?.signatory} />
                            )
                        })
                    }
                </div>
            </span>
            <span className='pagination'>
                <img onClick={() => pageChangeHandler(-1)} className='right' src="/assets/icons/arrow.svg" alt="" />
                <p dir='ltr'>{pages?.current} of {pages?.totalPages}</p>
                <img onClick={() => pageChangeHandler(1)} className='left' src="/assets/icons/arrow.svg" alt="" />
            </span>
        </div>
    )
}
