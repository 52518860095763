import React, { Fragment, useContext, useEffect, useState } from 'react'
import './SignatoriesEntry.scss'
import FormAddPerson from '../FormAddPerson/FormAddPerson'
import SetPersonCount from '../SetPersonCount/SetPersonCount'
import PageSubTitle from '../PageSubTitle/PageSubTitle'
import ButtonsFooter from '../ButtonsFooter/ButtonsFooter'
import { useNavigate } from 'react-router-dom'
import { RequestInputContext } from '../../pages/ManageRequests/CreateRequest'
import { createRequest } from '../../redux/actionCreator'
import { toast } from 'react-toastify'
import { singnatoriesEntryValidation } from '../../utils/validation'

export default function SignatoriesEntry({ setPage }) {

    const navigate = useNavigate()
    const { inputs, setInputs, inputErrors, setInputErrors } = useContext(RequestInputContext)
    const [signatoryCount, setSignatoryCount] = useState(1)
    const [updater, setUpdater] = useState(true)

    function onSaveHandler() {
        if (inputs.Signatory.length !== parseInt(signatoryCount)) {
            toast.error('All signatories are not selected', { position: "bottom-center", autoClose: 3000 })
            return
        }
        inputs.Signatory && inputs.Signatory.forEach(item => {
            if (item.name === undefined) {
                toast.error('Signatory name is not selected', { position: "bottom-center", autoClose: 3000 })
                return
            }
        })

        const formdata = new FormData()
        formdata.append('title', inputs.title)
        formdata.append('description', inputs.description)
        formdata.append('pdf', inputs.pdf)
        inputs.Signatory.forEach((item, i) => {
            formdata.append(`signatory[${i}]`, item.name);
            formdata.append(`comment_allowed[${i}]`, item.comment_allowed || false);
        });
        formdata.append('secretery', inputs.Secretary[0]?.name)
        createRequest(formdata, (res) => {
            if (res.status) {
                toast.success(res.message, { position: "bottom-center", autoClose: 3000 })
                navigate('document/' + res.requestdata._id)
            }
        })
    }

    useEffect(() => {
        setUpdater(false)
        setInputs(s => ({ ...s, Signatory: [] }))
        setTimeout(() => {
            setUpdater(true)
        }, 100);
    }, [signatoryCount])

    // console.log({ inputs })

    return (
        <div id='SignatoriesEntry'>
            <section className='title'>
                <PageSubTitle name={'Signatories'} />
            </section>
            <section>
                <SetPersonCount name={'signatories'} setState={setSignatoryCount} />
            </section>
            <hr />
            {
                updater && Array.from({ length: signatoryCount }).map((ele, i) => {
                    return (
                        <Fragment key={i}>
                            <section>
                                <FormAddPerson name={'Signatory'} serialNo={i + 1} />
                            </section>
                            <hr />
                        </Fragment>
                    )
                })
            }
            <section>
                <FormAddPerson name={'Secretary'} serialNo={1} />
            </section>
            <section>
                <ButtonsFooter
                    page={'SignatoriesEntry'}
                    onCancel={() => console.log('cancel clicked')}
                    onBack={() => setPage(1)}
                    onSave={onSaveHandler}
                />
            </section>
        </div>
    )
}