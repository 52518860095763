import React, { useContext, useState } from 'react'
import './ButtonsFooter.scss'
import { Link } from 'react-router-dom'
import { RequestInputContext } from '../../pages/ManageRequests/CreateRequest'

export default function ButtonsFooter({ page, onCancel, onNext, onSave, onBack, inputs, setInputs }) {

    const flag = page === 'RequestDetailsEntry' ? 1
        : page === 'AssignSignatory' ? 2
            : page === 'SignatoriesEntry' ? 3
                : 0

    let inputErrors, setInputErrors, initialInputObject;
    const CreateRequestContextValues = useContext(RequestInputContext)
    if (CreateRequestContextValues) { ({ inputs, setInputs, inputErrors, setInputErrors, initialInputObject } = CreateRequestContextValues) }

    // console.log([inputErrors])
    return (
        <div id='ButtonsFooter'>
            {
                flag === 1 ?
                    <span className='attachment-button'>
                        <label className='attachment-button' htmlFor='file-attach-button'>
                            {inputs.pdf &&
                                <span className='attached-file'>
                                    <img src="/assets/images/pdf.png" alt="" />
                                    <p>{inputs?.pdf.name.substring(0, 16)}</p>
                                </span>
                            }
                            <img src="/assets/icons/attachment-pin.svg" alt="" />
                            <p>Add Attachment</p>
                            <input type="file" name="" id="file-attach-button" hidden
                                onClick={() => setInputErrors(initialInputObject())}
                                onChange={(e) => (setInputs(s => ({ ...s, pdf: e.target.files[0] })))}
                                // onChange={(e) => (console.log(e))}
                                accept='application/pdf' />
                        </label>
                        {inputErrors.pdf && <p className='error-message'>{inputErrors.pdf}</p>}
                    </span>
                    : flag === 3 ?
                        <button onClick={() => onBack()}>Back</button>
                        : null
            }
            <span className='end-buttons'>
                <button className='cancel' onClick={() => onCancel()}>Cancel</button>
                {
                    flag === 1 ? <button onClick={() => onNext()}>Next</button>
                        : (flag === 2 || flag === 3) ? <button onClick={() => onSave()} > Save</button>
                            : null
                }
            </span>
        </div >
    )
}
