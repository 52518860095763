import React, { useEffect, useState } from 'react'
import './FormSearch.scss'
import { getAllSubUnits, getAllUnits, getAllUser } from '../../redux/actionCreator'
import Select from 'react-select'

export default function FormSearch({ onSearch }) {
    const [inputs, setInputs] = useState()
    const [dropdowns, setDropdowns] = useState()

    function inputChangeHandler(field, value) {
        setInputs(s => ({ ...s, [field]: value }))
        // onChange && onChange(inputs)
        field === 'unit' && subUnitsPopulate(value)
    }

    useEffect(() => {
        getAllUnits({}, (res) => setDropdowns(s => ({ ...s, units: res })))
    }, [])

    function subUnitsPopulate(value) {
        getAllSubUnits(value, (res) => { setDropdowns(s => ({ ...s, subUnits: res.subunit_details })) })
    }
    // function usersPopulate() {

    // }

    // function searchButtonHandler() {
    //     // let formdata = new FormData()
    //     // formdata.append('unit', inputs?.unit)
    //     // formdata.append('subunit', inputs?.subunit)
    //     // getAllUser(formdata, (res) => {
    //     //     console.log({ res })
    //     //     onSearch && onSearch(res)
    //     // })
    // }

    // console.log({ dropdowns })
    // console.log({ inputs })

    return (
        <div id='FormSearch'>
            <span>
                <label htmlFor="">Unit</label>
                {/* <select name="" id="" defaultValue={''} onChange={(e) => inputChangeHandler('unit', e.target.value)} >
                    <option value="" hidden>All</option>
                    {dropdowns?.units && dropdowns.units.map((item, i) => {
                        return (
                            <option key={i} value={item._id}>{item.unit_name}</option>
                        )
                    })}
                </select> */}
                <Select
                    name="unit"
                    options={dropdowns?.units}
                    // isDisabled={fullUserList?.length == 0}
                    hideSelectedOptions={false}
                    // value={user._id}
                    getOptionLabel={(option) => option.unit_name}
                    getOptionValue={(option) => option._id}
                    className="basic-select"
                    classNamePrefix="select"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: "#31D68F",
                            primary: "#0D7B58",
                        },
                    })}
                    styles={{
                        control: base => ({
                            ...base,
                            height: 48,
                            // minHeight: 35
                        })
                    }}
                    onChange={(e) => inputChangeHandler('unit', e._id)}
                />
                <img className='icon arrow' src="/assets/icons/arrow-down.svg" alt="" />
                {/* <p className='error-message'>error message</p> */}
            </span>
            <span>
                <label htmlFor="">Subunit</label>
                {/* <select name="" id="" defaultValue={''} onChange={(e) => inputChangeHandler('subunit', e.target.value)} >
                    <option value="" hidden>All</option>
                    {dropdowns?.subUnits && dropdowns.subUnits.map((item, i) => {
                        return (
                            <option value={item._id}>{item.subunit_name}</option>
                        )
                    })}
                </select> */}
                <Select
                    name="unit"
                    options={dropdowns?.subUnits}
                    // isDisabled={fullUserList?.length == 0}
                    hideSelectedOptions={false}
                    // value={user._id}
                    getOptionLabel={(option) => option.subunit_name}
                    getOptionValue={(option) => option._id}
                    className="basic-select"
                    classNamePrefix="select"
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: "#31D68F",
                            primary: "#0D7B58",
                        },
                    })}
                    styles={{
                        control: base => ({
                            ...base,
                            height: 48,
                            // minHeight: 35
                        })
                    }}
                    onChange={(e) => inputChangeHandler('subunit', e._id)}
                />
                <img className='icon arrow' src="/assets/icons/arrow-down.svg" alt="" />
                {/* <p className='error-message'>error message</p> */}
            </span>
            {/* 
            <span>
                <label htmlFor="">Department</label>
                <select name="" id="" defaultValue={''} onChange={(e) => inputChangeHandler('department', e.target.value)} >
                    <option value="" hidden>All</option>
                    <option value=""></option>
                </select>
                <img className='icon arrow' src="/assets/icons/arrow-down.svg" alt="" />
                <p className='error-message'>error message</p> 
            </span> 
            */}
            {/* <span>
                <label htmlFor="">Search</label>
                <input type="text" placeholder='Search Users' onChange={(e) => inputChangeHandler('search_key', e.target.value)} />
                <img className='icon' src="/assets/icons/search.svg" alt="" />
             <p className='error-message'>error message</p> 
            </span> */}
            <span className='button'>
                <button onClick={() => onSearch({ unit: inputs?.unit, subunit: inputs?.subunit })}>Search</button>
            </span>
        </div>
    )
}