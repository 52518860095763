import React from 'react'
import './Header_Main_Layout.scss'
import { useSelector } from 'react-redux'
import { User } from '../../redux/commonReducer'

export default function Header_Main_Layout() {

    const user = JSON.parse(localStorage.getItem('user'))
    // let user = useSelector(User)

    return (
        <div id='Header_Main_Layout'>
            <span>
                <img src="/assets/icons/profile-icon.svg" alt="" />
                <p>{user.username}</p>
            </span>
        </div>
    )
}
