import React, { useState } from 'react'
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle'
import RequestDetails from '../../components/RequestDetails/RequestDetails'

export default function RequestDetailsPage({ page = '' }) {
    return (
        <div id='PendingRequestDetails'>
            <h1 className='page-title'>{page.toUpperCase()} REQUEST DETAILS</h1>
            <section className='title'>
                <PageSubTitle name={'Details'} />
            </section>
            <section>
                <RequestDetails page={page} />
            </section>
        </div>
    )
}