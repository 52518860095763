import React, { useContext } from 'react'
import './RequestDetailsEntry.scss'
import PageSubTitle from '../PageSubTitle/PageSubTitle'
import FormRequestDetails from '../FormRequestDetails/FormRequestDetails'
import ButtonsAtachment from '../ButtonsAtachment/ButtonsAtachment'
import ButtonsFooter from '../ButtonsFooter/ButtonsFooter'
import { requestDetailEntryValidation } from '../../utils/validation'
import { RequestInputContext } from '../../pages/ManageRequests/CreateRequest'

export default function RequestDetailsEntry({ setPage }) {

    const { inputs, setInputs, inputErrors, setInputErrors, initialInputObject } = useContext(RequestInputContext)

    async function nextPageHandler() {
        // console.log({ inputs })
        // console.log({ inputErrors })
        let isValidated = await requestDetailEntryValidation({
            title: inputs.title,
            description: inputs.description,
            pdf: inputs.pdf,
        }, setInputErrors)
        isValidated && setPage(2)
    }
    return (
        <div id='RequestDetailsEntry'>
            <section className='title'>
                <PageSubTitle name={'Enter Request Details'} />
            </section>
            <section>
                <FormRequestDetails />
            </section>
            <section>
                <ButtonsFooter
                    page={'RequestDetailsEntry'}
                    onCancel={async () => (await setInputErrors(initialInputObject()), setInputs(initialInputObject()))}
                    onNext={nextPageHandler}
                />
            </section>
        </div>
    )
}
