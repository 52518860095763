import React, { useEffect, useState } from 'react'
import FormSearch from '../../components/FormSearch/FormSearch'
import Table from '../../components/Table/Table'
import PaginationRow from '../../components/PaginationRow/PaginationRow'
import Modal from '../../Tools/Modal/Modal'
import { addSignature, getAllUser, getUserList } from '../../redux/actionCreator'
import { API_BASE_URL } from '../../constants/configuration'
import { toast } from 'react-toastify'

export default function AddSignature() {

    const [showModal, setShowModal] = useState(false)
    const [uploadedImg, setUploadedImg] = useState()
    const [data, setData] = useState()
    const [toEditSign, setToEditSign] = useState()
    const [fileInput, setFileInput] = useState()
    const [fileInputError, setFileInputError] = useState()
    const [searchKey, setSearchKey] = useState('')

    let Titles = [
        { title: 'Unit', key: 'unit' },
        { title: 'Subunit', key: 'subunit' },
        { title: 'Users', key: 'name' },
        { title: 'Signature', key: 'signature' },
        { title: 'Actions', key: 'actions' },
    ]

    useEffect(() => {
        getData()
    }, [searchKey])

    useEffect(() => {
        setFileInput()
    }, [showModal])

    function getData(values = {}) {
        getUserList(values, (res) => {
            setData(res.filter(item => item.name.includes(searchKey)).map((d) => {
                return {
                    unit: d.unit[0].unit_name,
                    subunit: d.subunit[0].subunit_name,
                    name: d.name,
                    username: d.username,
                    signature: d.signature ? 'Available' : '',
                    signature_path: d.signature,
                    _id: d._id
                }
            }))
        })
    }
    function searchHandler(value) {
        setSearchKey(value)
    }

    function onUploadHandler() {
        setFileInputError()
        if (fileInput?.type === 'image/png') {
            let formdata = new FormData()
            formdata.append('id', toEditSign.userId)
            formdata.append('signature', fileInput)
            addSignature(formdata, (res) => {
                if (res.status) {
                    setShowModal(false)
                    toast.success(res.message, { position: "bottom-center", autoClose: 3000 })
                    getData()
                }
            })
        } else {
            setFileInputError('Upload PNG files only')
        }
    }

    // console.log({ data })
    // console.log({ toEditSign })

    return (
        <div id='AddSignature'>
            <h1 className='page-title'>ADD SIGNATURE</h1>
            <section>
                <FormSearch onSearch={(result) => getData(result)} />
            </section>
            <section>
                <PaginationRow page={'AddSignature'} onSearch={(value) => searchHandler(value)} />
            </section>
            <section>
                <Table page={'add signature'} titles={Titles} data={data}
                    setModalShow={setShowModal} onEdit={(res) => setToEditSign(res)} />
            </section>
            <Modal setShow={setShowModal} show={showModal} >
                <img className='close-icon' src="" alt="" />
                <div className='sign'>
                    <p>{toEditSign?.username}</p>
                    <span>
                        {
                            fileInput ? <img src={URL.createObjectURL(fileInput)} alt="" />
                                : toEditSign?.signPng && <img src={`${API_BASE_URL}/signature/${toEditSign?.signPng}`} alt="" />
                        }
                        <img className='delete-icon' src="/assets/icons/delete red.svg" onClick={() => (setToEditSign(s => ({ ...s, signPng: '' })), setFileInput())} alt="" />
                    </span>
                </div>
                <div className='input'>
                    <button className='upload'>
                        <label htmlFor="signatureUploadBtn">
                            Upload Signature
                            <input type="file" id='signatureUploadBtn' hidden accept='image/png'
                                onChange={(e) => setFileInput(e.target.files[0])} />
                        </label>
                    </button>
                    <button className='save' onClick={() => onUploadHandler()} > Upload</button>
                    {fileInputError && <p className='instruction'>{fileInputError}</p>}
                </div>
            </Modal >
        </div >
    )
}