import React, { Fragment, createContext, useState } from 'react'
import './AssignSignatory.scss'
import FormAddPerson from '../FormAddPerson/FormAddPerson'
import PageSubTitle from '../PageSubTitle/PageSubTitle'
import ButtonsFooter from '../ButtonsFooter/ButtonsFooter'
import { useLocation, useNavigate } from 'react-router-dom'
import SetPersonCount from '../SetPersonCount/SetPersonCount'
import { assignSignatory } from '../../redux/actionCreator'
import { toast } from 'react-toastify'

export const AssignSignatoryContext = createContext()
export default function AssignSignatory() {

    const navigate = useNavigate()
    const location = useLocation()
    const [inputs, setInputs] = useState({ Signatory: [], Secretary: [] })
    const [inputErrors, setInputErrors] = useState({})
    const [signatoryCount, setSignatoryCount] = useState(1)

    // console.log({ location })
    function onSaveHandle() {
        // console.log({ inputs })

        if (inputs.Signatory.length === 0) {
            toast.error('No signatories are selected', { position: "bottom-center", autoClose: 3000 })
            return
        }
        let formdata = new FormData()
        formdata.append('level', location.state.level)
        formdata.append('comment', location.state.comment || '')
        formdata.append('id', location.state.id)
        formdata.append('secretery', inputs?.Secretary[0]?.name)
        inputs?.Signatory.forEach((item, i) => {
            formdata.append(`comment_allowed[${i}]`, item.comment_allowed || false)
            formdata.append(`signatory[${i}]`, item.name)
        })
        assignSignatory(formdata, (res) => {

            // console.log('assign signatory', res)
            if (res.status) {
                toast.success('Signatories assigned successfully', { position: "bottom-center", autoClose: 3000 })
                // navigate('../pending_requests')
                navigate('document/' + res.request_id)

            }
        })
    }


    return (
        <AssignSignatoryContext.Provider value={{ setInputErrors, inputErrors, setInputs, inputs }}>

            <div id='AssignSignatory'>
                <h1 className='page-title'>REQUEST DETAILS</h1>
                <section className='title'>
                    <PageSubTitle name={'Assign Signatory'} />
                </section>
                <section>
                    <SetPersonCount name={'signatories'} setState={setSignatoryCount} />
                </section>
                {
                    Array.from({ length: signatoryCount }).map((ele, i) => {
                        return (
                            <Fragment key={i}>
                                <section>
                                    <FormAddPerson name={'Signatory'} serialNo={i + 1} />
                                </section>
                                <hr />
                            </Fragment>
                        )
                    })
                }
                <section>
                    <FormAddPerson name={'Secretary'} serialNo={1} />
                </section>
                <section>
                    <ButtonsFooter
                        page={'AssignSignatory'}
                        onCancel={() => console.log('cancel clicked')}
                        onBack={() => { }}
                        onSave={onSaveHandle}
                    />
                </section>
            </div>
        </AssignSignatoryContext.Provider>
    )
}
