import React, { createContext, useState } from 'react'
import RequestDetailsEntry from '../../components/RequestDetailsEntry/RequestDetailsEntry'
import SignatoriesEntry from '../../components/SignatoriesEntry/SignatoriesEntry'

export const RequestInputContext = createContext()
export default function ManageRequests() {

    const [page, setPage] = useState(1)
    const [inputs, setInputs] = useState(initialInputObject())
    const [inputErrors, setInputErrors] = useState(initialInputObject())

    function initialInputObject() {
        return {
            Signatory: [],
            Secretary: [],
            title: '',
            description: '',
            pdf: '',
        }
    }

    return (
        <RequestInputContext.Provider value={{ setInputErrors, inputErrors, setInputs, inputs, initialInputObject }}>
            <div id='ManageRequests'>
                <h1 className='page-title'>CREATE NEW REQUEST</h1>
                <section>
                    {
                        page === 1 ? <RequestDetailsEntry
                            setPage={setPage}
                        /> :
                            page === 2 ? <SignatoriesEntry
                                setPage={setPage}
                            /> : null
                    }
                </section>
            </div>
        </RequestInputContext.Provider>
    )
}