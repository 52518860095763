import React, { useEffect, useState } from 'react'
import './PaginationRow.scss'

export default function PaginationRow({ page, onSearch, onCount }) {

    let flag = page === 'AddSignature' ? 1
        : 0

    const [lineCount, setLineCount] = useState(10)

    function searchHandler(value) {
        onSearch && onSearch(value)
    }

    function lineCountHandler(value) {
        setLineCount(s => {
            s = parseInt(s)
            if (s === 0 && value === -1) return s
            return s += value
        })

    }

    useEffect(() => {
        onCount && onCount(lineCount)
    }, [lineCount])

    function onChangeHandler(value) {
        setLineCount(parseInt(value))
    }


    return (
        <div id='PaginationRow'>
            <span className='Count'>
                <p>entries</p>
                <span className='number-input'>
                    <input type="number" value={lineCount} placeholder='0' onChange={(e => onChangeHandler(e.target.value))} />
                    <img className='up' onClick={() => lineCountHandler(1)} src="/assets/icons/arrow-down.svg" alt="" />
                    <img className='down' onClick={() => lineCountHandler(-1)} src="/assets/icons/arrow-down.svg" alt="" />
                </span>
                <p>Show</p>
            </span>
            {flag !== 1 &&
                <span className='Search'>
                    <input type="text" placeholder='Search' onChange={(e) => searchHandler(e.target.value)} />
                    <img src="/assets/icons/search.svg" alt="" />
                </span>
            }
        </div>
    )
}