import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState: {
        value: true,
    },
    reducers: {
        updateRedux: (state, data) => {
            state[data.payload.key] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;


export const User = (state) => state.commonReducer.user;
// export const PdfHeaderDetails = (state) => state?.commonReducer.pdfHeaderDetails;

export default commonSlice.reducer;