import React from 'react'
import './SetPersonCount.scss'

export default function SetPersonCount({ name, setState }) {
    return (
        <div id='SetPersonCount'>
            <label htmlFor="">Set number of {name}</label>
            <br />
            <span>
                <select name="" id="" defaultValue={'1'} onChange={(e) => setState(e.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
                <img src="/assets/icons/arrow-down.svg" alt="" />
            </span>
        </div>
    )
}
