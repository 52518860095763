import React, { Fragment, useEffect, useState } from 'react'
import './RequestDetails.scss'
import { approveRequest, getRequestDetail, rejectRequest, returnRequest, getUserData } from '../../redux/actionCreator'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import { capitalize } from '../../utils'

export default function RequestDetails({ page }) {

    const urlParams = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [input, setInput] = useState()
    const [inputError, setInputError] = useState()
    const [currentUserData, setCurrentUserData] = useState()
    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        getRequestDetail(urlParams.id, (res) => {
            // res.request_details.type = 'secretary'
            setData(res.request_details)
        })
    }, [])


    useEffect(() => {
        data && data?.[data?.type]?.forEach((item) => {
            // console.log(item.priority, item[data?.type]._id, user._id)
            if (item.priority && item[data?.type]._id === user._id) {
                setCurrentUserData(item)
            }
        })
    }, [data])

    // console.log({user})
    // console.log(currentUserData.comment_allowed)

    let flag = data?.type === 'signatory' ? 1
        : data?.type === 'secretery' ? 2
            : 0


    function rejectHandler() {
        if (!currentUserData?.comment_allowed && !input) {
            setInputError('Comment is required')
            return
        }
        const obj = { id: data?._id, comment: input, level: data?.currentLevel }
        rejectRequest(obj, (res) => {
            if (res.status) {
                toast.success('Request is rejected', { position: "bottom-center", autoClose: 3000 })
                navigate('../')
            }
        })
    }
    function returnHandler() {
        if (!currentUserData?.comment_allowed && !input) {
            setInputError('Comment is required')
            return
        }
        const obj = { id: data?._id, comment: input, level: data?.currentLevel }
        returnRequest(obj, (res) => {
            if (res.status) {
                toast.success('Request is returned', { position: "bottom-center", autoClose: 3000 })
                navigate('../')
            }
        })
    }
    function approveHandler() {
        if (!currentUserData?.comment_allowed && !input) {
            setInputError('Comment is required')
            return
        }
        const obj = { id: data?._id, comment: input }
        approveRequest(obj, (res) => {
            if (res.status) {
                toast.success('Request is approved', { position: "bottom-center", autoClose: 3000 })
                navigate('../')
                // console.log(res.nextSignatory)
            }
        })
    }

    console.log({ data })
    console.log(data?.signatory[0].priority)
    return (
        <div id='RequestDetails'>
            <section className='status-details'>
                <span>
                    <p>Status</p>
                    <p className={'status ' + data?.currentStatus}>{capitalize(data?.currentStatus || '')}</p>
                </span>
                <span>
                    <p>Request ID</p>
                    <p>{data?.reqId}</p>
                </span>
                <span>
                    <p>Requester</p>
                    <p>{data?.created_by.name}</p>
                </span>
                <span>
                    <p>Created Date</p>
                    <p dir='ltr'>{moment(data?.createdAt).format('DD MMM yyyy')}</p>
                </span>
            </section>
            <hr />
            <section className='description'>
                <span>
                    <p>Title</p>
                    <p>{data?.title}</p>
                </span>
                <span>
                    <p>Description</p>
                    <p>{data?.description}</p>
                </span>
            </section>
            <hr />
            <section className='file'>
                <p>Request File</p>
                <p>Document <Link target='_blank' to={`http://localhost:8000/request/pdf/${data?.signatory[0].priority ? '' : 'output-'}${data?.pdf}`}><img src="/assets/icons/eye.svg" alt="" /></Link></p>
            </section>
            <section className='signatory-table'>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '150px' }}>Name</th>
                            <th style={{ width: '150px' }}>Unit</th>
                            <th style={{ width: '150px' }}>Subunit</th>
                            <th style={{ width: '150px' }}>Status</th>
                            <th>Approval Time</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.secretery.map((sec_item, ind) => {
                                // console.log('secuii', sec_item)
                                // console.log(data?.signatory.filter(o => o.currentlevel === ind + 1))
                                // console.log('level check', data?.signatory[0].level, ind)
                                return (
                                    <Fragment key={ind} >
                                        {
                                            data?.signatory.filter(o => o.level === sec_item.level).map((item, i) => {
                                                // console.log('filter signs', item)
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.signatory.name}</td>
                                                        <td>{item.signatory.unit[0].unit_name}</td>
                                                        <td>{item.signatory.subunit[0].subunit_name}</td>
                                                        <td>
                                                            <span className={'status ' + (item.status)}>
                                                                {capitalize(item.status)}
                                                            </span>
                                                        </td>
                                                        <td dir='ltr'>{moment(item.updatedAt).format('DD MMM yyyy')}</td>
                                                        <td>
                                                            <p>{item.comment}</p>
                                                        </td>
                                                    </tr >
                                                )
                                            })
                                        }
                                        {sec_item.comment &&
                                            <tr>
                                                <td className='key'>Secretary Note</td>
                                                <td colSpan='100%'>
                                                    {sec_item.comment}
                                                </td>
                                            </tr>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                        {
                            data?.signatory
                                .filter(it => !(data?.secretery.some(em => em.level === it.level)))
                                .map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.signatory.name}</td>
                                            <td>{item.signatory.unit[0].unit_name}</td>
                                            <td>{item.signatory.subunit[0].subunit_name}</td>
                                            <td>
                                                <span className={'status ' + (item.status)}>
                                                    {capitalize(item.status)}
                                                </span>
                                            </td>
                                            <td dir='ltr'>{moment(item.updatedAt).format('DD MMM yyyy')}</td>
                                            <td>
                                                <p>{item.comment}</p>
                                            </td>
                                        </tr>
                                    )
                                })
                        }
                    </tbody >
                </table>
            </section>

            {/* {
                flag === 2 &&
                <section className='comment'>
                    <p>Comment</p>
                    <p>consectetur adipiscing elit.sit amet, consectetur adipiscing elit.sit amet, consectetur adipiscing elit.sit amet, adipiscing elit.sit amet, adipiscing elit.sit amet,consectetur adipiscing elit.sit amet, consectetur adipis</p>
                </section>
            }
            {
                flag === 1 &&
                <section className='comment'>
                    <p>Secretary Notes</p>
                    <p>consectetur adipiscing elit.sit amet, consectetur adipiscing elit.sit amet, consectetur adipiscing elit.sit amet, adipiscing elit.sit amet, adipiscing elit.sit amet,consectetur adipiscing elit.sit amet, consectetur adipis</p>
                </section>
            } */}
            {
                data?.status !== 1 && page !== 'in-Progress' && page !== '' &&
                <section className='notes'>
                    {flag === 1 ? <p>Comment</p> : flag === 2 ? <p>Notes</p> : null}  {/* or notes */}
                    <textarea onChange={e => (setInputError(), setInput(e.target.value))} className={inputError ? 'error' : ''}
                        name="" id="" cols="30" rows="10" placeholder='Comment here'></textarea>
                    {inputError && <p className='error-message'>{inputError}</p>}
                </section>
            }
            {page !== 'in-Progress' && page !== '' &&
                <section className='buttons'>
                    {
                        flag === 1 &&
                        <>
                            <button className='Reject' onClick={() => rejectHandler()}>Reject</button>
                            <button className='Return' onClick={() => returnHandler()}>Return</button>
                            <button className='Approve' onClick={() => approveHandler()}>Approve</button>
                        </>
                    }
                    {flag === 2 &&
                        <button className='Approve' onClick={() => navigate('../../assign_signatory', { state: { level: data.currentLevel + 1, comment: input, id: data._id, } })}>Assign Signatory</button>
                    }
                </section>
            }
        </div >
    )
}
