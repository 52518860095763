import React, { useEffect, useState } from 'react'
import { getUserList, manageRoles } from '../../redux/actionCreator'
import { API_BASE_URL } from '../../constants/configuration'
import PaginationRow from '../../components/PaginationRow/PaginationRow'
import Table from '../../components/Table/Table'
import Modal from '../../Tools/Modal/Modal'
import FormSearch from '../../components/FormSearch/FormSearch'
import { capitalize } from '../../utils'

export default function AssignRole() {



    const [showModal, setShowModal] = useState(false)
    const [checkboxModal, setCheckBoxModal] = useState(false)
    const [checkBoxDetails, setCheckBoxDetails] = useState()
    const [uploadedImg, setUploadedImg] = useState()
    const [data, setData] = useState()
    const [toEditSign, setToEditSign] = useState()
    const [searchKey, setSearchKey] = useState('')

    let Titles = [
        { title: 'Unit', key: 'unit' },
        { title: 'Subunit', key: 'subunit' },
        { title: 'Users', key: 'name' },
        { title: 'Secretary Permission', key: 'secretary_permission' },
    ]

    useEffect(() => {
        getData()
    }, [searchKey])

    function getData(values = {}) {
        getUserList(values, (res) => {
            setData(res.filter(item => item.name.includes(searchKey)).map((d) => {
                return {
                    unit: d.unit[0].unit_name,
                    subunit: d.subunit[0].subunit_name,
                    name: d.name,
                    username: d.username,
                    _id: d._id,
                    is_secretery: d.is_secretery
                }
            }))
        })
    }
    // console.log(data)

    function searchHandler(value) {
        setSearchKey(value)
    }
    function onCheckboxConfirm() {
        manageRoles({ id: checkBoxDetails.id, is_secretery: checkBoxDetails.value }, (res) => {
            data.forEach(item => {
                if (item._id === checkBoxDetails.id) {
                    item.is_secretery = checkBoxDetails.value
                }
            })
            setCheckBoxModal(false)
        })
    }

    function checkboxChangeHandler(e, id, user) {
        setCheckBoxModal(true)
        setCheckBoxDetails({ value: e, id: id, user: user })
    }

    return (
        <div id='AssignRole'>
            <h1 className='page-title'>ASSIGN ROLE</h1>
            <section>
                <FormSearch onSearch={(result) => getData(result)} />
            </section>
            <section>
                <PaginationRow page={'AddSignature'} onSearch={(value) => searchHandler(value)} />
            </section>
            <section>
                <Table page={'add signature'} titles={Titles} data={data}
                    onCheckboxChange={(e, id, user) => checkboxChangeHandler(e, id, user)}
                    setModalShow={setShowModal} onEdit={(res) => setToEditSign(res)} />
            </section>
            <Modal show={checkboxModal} setShow={setCheckBoxModal}  >
                <div id='CheckBoxModal'>
                    <h3 dir='ltr'>Are you sure want to change access <br /> permission of {capitalize(checkBoxDetails?.user || '')} ?</h3>
                    <div className='buttons'>
                        <button className='confirm' onClick={() => onCheckboxConfirm()}>Confirm</button>
                        <button className='cancel' onClick={() => setCheckBoxModal(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}