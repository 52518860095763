import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import { updateRedux } from './commonReducer';

const formHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDI1NGZlNTRjMDRkN2I4NjgyNGIxYmMiLCJpYXQiOjE2ODAxODAyMDIsImV4cCI6MTY4MDI2NjYwMn0._2vTRyPPMTUkoSsSoojI04sdTQhR2QHys_MdHj2_lwk`
  },
}

const header = {
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

export const login = (data, callback) => (dispatch) => {
  console.log(`${API_BASE_URL}`)
  axios.post(`${API_BASE_URL}admin/login`, data).then(res => {
    // console.log(res.data)
    if (res.data.status) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('user', JSON.stringify(res.data))
      dispatch(updateRedux({ key: 'user', value: res.data }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

// export const logout = () => () => {
//   localStorage.removeItem('token')
//   localStorage.removeItem('user')
// }

export const getAllUnits = (data, callback) => {
  axios.get(`${API_BASE_URL}admin/getUnit`, header, data).then(res => {
    // console.log('getAllUnits', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'units', value: res.data.unit_details }))
      if (callback) { callback(res.data.unit_details) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getAllSubUnits = (id, callback) => {
  axios.get(`${API_BASE_URL}admin/getSubunit/${id}`, header).then(res => {
    // console.log('getAllSubunits', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getUser = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/getUser`, data, header).then(res => {
    // console.log('getUser', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'users', value: res.data.user }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const createRequest = (data, callback) => {
  // console.log('createRequest', data)
  axios.post(`${API_BASE_URL}admin/addRequest`, data, header).then(res => {
    console.log('createRequest', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getPendingRequest = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/getPendingRequest`, data, header).then(res => {
    // console.log('getPendingRequest', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data.request_details) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const inProgressRequest = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/inProgressRequest`, data, header).then(res => {
    // console.log('inProgressRequest', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data.request_details) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getRequestDetail = (id, callback) => {
  // console.log('reqid', id)
  axios.get(`${API_BASE_URL}admin/getRequestDetail/${id}`, header).then(res => {
    // console.log('getRequestDetail', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getRequest = (callback) => {
  // console.log('reqid', id)
  axios.post(`${API_BASE_URL}admin/getRequest`, {}, header).then(res => {
    // console.log('getRequest', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const approveRequest = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/approveRequest`, data, header).then(res => {
    // console.log({ res })
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const rejectRequest = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/rejectRequest`, data, header).then(res => {
    // console.log({ res })
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const returnRequest = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/returnRequest`, data, header).then(res => {
    // console.log('response', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const assignSignatory = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/assignSignatory`, data, header).then(res => {
    // console.log('assignSignatory', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const submitPDF = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/submitPDF`, data, header).then(res => {
    // console.log('submitPDF', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getAllUser = (data, callback) => {
  console.log('data all', data)
  axios.post(`${API_BASE_URL}admin/getAllUser`, data, header).then(res => {
    console.log('getAllUser', res.data.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getUserList = (data, callback) => {
  // console.log('data all', data)
  axios.post(`${API_BASE_URL}admin/getUserList`, data, header).then(res => {
    // console.log('getUserList', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getUserData = async (id, callback) => {
  let response = 'hello world'
  return axios.get(`${API_BASE_URL}admin/getUserData/${id}`, header).then(res => {
    return res.data
  }).catch(err => console.log(err))
  return response;
}

export const closedRequest = (data, callback) => {
  axios.post(`${API_BASE_URL}admin/closedRequest`, data, header).then(res => {
    console.log('closedRequest', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data.request_details) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const manageRoles = (data, callback) => {
  // console.log(data)
  axios.post(`${API_BASE_URL}admin/manageRoles`, data, header).then(res => {
    // console.log('manageRoles', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const addSignature = (data, callback) => {
  console.log(data)
  axios.post(`${API_BASE_URL}admin/addSignature`, data, header).then(res => {
    console.log('addSignature', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const setPdfHeaderDetails = (data) => dispatch => {
  dispatch(updateRedux({ key: 'pdfHeaderDetails', value: data }))
}

export const setPdfHeaderSelectedBtn = (data) => dispatch => {
  dispatch(updateRedux({ key: 'pdfHeaderBtns', value: data }))
}

